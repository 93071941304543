var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"weyaVtBjsyO0g1HSfGEq5"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from "@sentry/nextjs";

Sentry.init({
  enabled: process.env.NODE_ENV !== "development",
  // eslint-disable-next-line i18next/no-literal-string
  dsn: "https://62bb8d48a8b8da4710a6baca04e1761e@o4507271372406784.ingest.de.sentry.io/4507272288206928",
  // eslint-disable-next-line i18next/no-literal-string
  tunnel: "/api/sentry-tunnel",
  enableTracing: false,
  debug: false,
});
